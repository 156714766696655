import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css"; 
import "mdbreact/dist/css/mdb.css";

ReactDOM.render(<App />, document.getElementById("root"));

// reportWebVitals();
